<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>
        {{ $t("add_package") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-text-field v-model="editedData.PackageName"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("package_name") }}
            </template>
          </v-text-field>
          <v-text-field
            v-model="editedData.PackageType"
            :error-messages="PackageTypeErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.PackageType.$touch()"
            @blur="$v.editedData.PackageType.$touch()"
          >
            <template v-slot:label>
              {{ $t("package_type") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.PackageDescription"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("package_description") }}
            </template>
          </v-text-field>
          <v-text-field v-model="editedData.PackageRate" type="number"
                        dense
                        outlined>
            <template v-slot:label>
              {{ $t("package_rate") }}
            </template>
          </v-text-field>
          <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="red darken-1" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "CreatePackageForm",
  props: ["success"],
  computed: {
    PackageNameErrors() {
      const errors = [];
      if (!this.$v.editedData.PackageName.$dirty) return errors;
      !this.$v.editedData.PackageName.required &&
        errors.push(`${this.$t("package_name")} ${this.$t("is_required")}`);
      return errors;
    },
    PackageTypeErrors() {
      const errors = [];
      if (!this.$v.editedData.PackageType.$dirty) return errors;
      !this.$v.editedData.PackageType.required &&
        errors.push(`${this.$t("package_type")} ${this.$t("is_required")}`);
      return errors;
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
        PackageName: "",
        PackageType: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      PackageName: { required },
      PackageType: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      this.updated = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let param = {
          PackageRate: parseInt(this.editedData.PackageRate),
          PackageName: this.editedData.PackageName,
          PackageType: this.editedData.PackageType,
          PackageDescription: this.editedData.PackageDescription,
        };
        axios
          .post("Hotel/InsertIntoHotelPackageAsync", param)
          .then((response) => {
            if (response.data.success) {
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Package added Succcessfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
