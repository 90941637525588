<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>{{ $t("edit_package") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-text-field v-model="editedData.packageName"
                        dense
                        outlined
            ><template v-slot:label>
              {{ $t("package_name") }}
            </template></v-text-field
          >
          <v-text-field
            v-model="editedData.packageType"
            :error-messages="packageTypeErrors"
            required
                        dense
                        outlined
            @input="$v.editedData.packageType.$touch()"
            @blur="$v.editedData.packageType.$touch()"
            ><template v-slot:label>
              {{ $t("package_type") }}
            </template></v-text-field
          >
          <v-text-field
            v-model="editedData.packageDescription"
                        dense
                        outlined
            ><template v-slot:label>
              {{ $t("package_description") }}
            </template></v-text-field
          >
          <v-text-field v-model="editedData.packageRate" type="number"
                        dense
                        outlined
            ><template v-slot:label>
              {{ $t("package_rate") }}
            </template></v-text-field
          >
          <!-- <v-checkbox v-model="formData.isActive" label="Activate User"></v-checkbox> -->
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="red darken-1" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "EditPackageForm",
  props: ["success", "editData"],
  computed: {
    packageNameErrors() {
      const errors = [];
      if (!this.$v.editedData.packageName.$dirty) return errors;
      !this.$v.editedData.packageName.required &&
        errors.push(`${this.$t("package_name")} ${this.$t("is_required")}`);
      return errors;
    },
    packageTypeErrors() {
      const errors = [];
      if (!this.$v.editedData.packageType.$dirty) return errors;
      !this.$v.editedData.packageType.required &&
        errors.push(`${this.$t("package_type")} ${this.$t("is_required")}`);
      return errors;
    },
  },
  data() {
    return {
      returnData: [],
      show: false,
      updated: false,
      editedData: {
        packageName: "",
        packageType: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      packageName: { required },
      packageType: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      const response = await axios.get(
        "Hotel/GetHotelPackageByIDAsync/" + this.editData
      );
      console.log(response.data);
      this.editedData = response.data;
      this.updated = false;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let param = {
          hotelPackageID: this.editedData.hotelPackageID,
          PackageRate: parseInt(this.editedData.packageRate),
          PackageName: this.editedData.packageName,
          PackageType: this.editedData.packageType,
          PackageDescription: this.editedData.packageDescription,
        };
        axios
          .post("Hotel/UpdateHotelPackageAsync", param)
          .then((response) => {
            if (response.data.success) {
              this.updated = true;
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "Package Updated Successfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.Fare = "";
    },
  },
};
</script>

<style scoped></style>
